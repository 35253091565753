<template>
  <div>
    <nav-bar />
    <resources-hero/>
    <Resources />
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/New/Footer.vue";
import NavBar from "../components/New/NavBar.vue";
import ResourcesHero from '../components/New/Resources-hero.vue';
import Resources from "../components/New/Resources.vue";
export default {
  components: {
    NavBar,
    Footer,
    Resources,
    ResourcesHero,
  },
};
</script>

<style lang="scss" scoped>
</style>