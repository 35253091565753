<template>
  <div class="container-fluid resources">

    <div class="row card-wrapper">

      <div class="col-md-4 ">
        <img src="../../assets/new/KOREA.jpg" class="img-responsive" alt="" />
        <h4 class="py-4">African Continental Free Trade Area: Opportunities and Challenges</h4>
        <div class="row">
            <div class="col-6 col-md-6">
                <a href="https://www.researchgate.net/publication/329450723_African_Continental_Free_Trade_Area_Opportunities_and_Challenges" class="download_btn">Read More</a>
            </div>
            <div class="col-6 col-md-6">
                <p class="date">November 2018</p>
            </div>
        </div>
      </div>

      <div class="col-md-4">
          <img src="../../assets/new/SUMMIT.jpg" class="img-responsive" alt="" />
        
        <h4 class="py-4">AfCFTA Economic Integration in Africa</h4>
        <div class="row">
            <div class="col-6 col-md-6">
                <a class="download_btn" href="https://www.imf.org/external/pubs/ft/fandd/2018/12/pdf/afcfta-economic-integration-in-africa-fofack.pdf">Read More</a>
            </div>
            <div class="col-6 col-md-6">
                <p class="date">December  2018</p>
            </div>
        </div>
      </div>

      <div class="col-md-4">
        <img src="../../assets/new/African-common.jpg" class="img-responsive" alt="" />
        <h4 class="py-4">AfCFTA Phase 1 Negotiations: Issues and Challenges for Eastern Africa</h4>
        <div class="row">
            <div class="col-6 col-md-6">
                <a class="download_btn" href="">Read  More</a>
            </div>
            <div class="col-6 col-md-6">
                <p class="date">September 2019</p>
            </div>
        </div>
      </div>

      <div class="col-md-4">
          <img src="../../assets/new/throughthepandemic_final-11.jpg" class="img-responsive" alt="" />
          <h4 class="py-4">Through the Pandemic and Beyond UNDP, AUC and Africa CDC Respond To Covid-19 In Africa</h4>
          <div class="row">
              <div class="col-6 col-md-6">
                  <a class="download_btn" target="_blank" href="https://au.int/en/documents/20210402/through-pandemic-and-beyond-respond-covid-19">Read  More</a>
              </div>
              <div class="col-6 col-md-6">
                <p class="date">April 02, 2021</p>
              </div>
          </div>
        </div>

        <div class="col-md-4">
          <img src="../../assets/new/diaspora_humanitarianism_english.jpg" class="img-responsive" alt="" />
          <h4 class="py-4">Mapping Study on the Role and Faces of African Diaspora Humanitarianism during COVID-19</h4>
          <div class="row">
              <div class="col-6 col-md-6">
                  <a class="download_btn" target="_blank" href="https://au.int/en/documents/20210320/study-role-and-faces-african-diaspora-humanitarianism-covid-19">Read  More</a>
              </div>
              <div class="col-6 col-md-6">
                <p class="date">March 20, 2021</p>
              </div>
          </div>
        </div>

        <div class="col-md-4">
          <img src="../../assets/new/decade_plan_of_action._eng-1.jpg" class="img-responsive" alt="" />
          <h4 class="py-4">African Decade for Technical, Professional, Entrepreneurial Training and Youth Employment (2019-2028)</h4>
          <div class="row">
              <div class="col-6 col-md-6">
                  <a class="download_btn" target="_blank" href="https://au.int/en/documents/20201107/african-decade-technical-professional-entrepreneurial-training-and-youth">Read  More</a>
              </div>
              <div class="col-6 col-md-6">
                <p class="date">November 07, 2020</p>
              </div>
          </div>
        </div>  

      </div>
    <!-- <div class="row pt-5">
      <div class="col-md-12 text-center">
        <button class="view_more">View more</button>
      </div>
    </div> -->

  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.resources {
  padding-left: 12%;
  padding-bottom: 20px;
  padding-right: 12%;
  padding-top: 100px;
}
.card-wrapper > div{
  margin-bottom:80px;
}
.img-responsive {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.view_more{
  border: 1px solid rgba(0,0,0,.7);
  outline:none;
  transition: all 0.5s;
  padding:10px 50px;
  background-color: transparent;
}
.download_btn{
  border: 1px solid rgba(0,0,0,.7);
  outline:none;
  transition: all 0.5s;
  padding:10px 20px;
  background-color: transparent;
  text-decoration: none;
  color:#000;
}
.view_more:focus, .view_more:active, .view_more:hover, .download_btn:hover{
  outline:none;
  background-color: rgb(235, 41, 41);
  color:#fff;
  border:1px solid  rgb(235, 41, 41);
}
.more {
  color: #8c8c8c;
}
.date {
  font-size: 14px;
  font-style: italic;
}
@media screen and (max-width: 1100px) {
  .resources{
      padding-right:5%;
      padding-left: 5%;
  }
}
@media screen and (max-width:768px) {
  .resources {
    padding-top:70px;
    padding-bottom:0px;
  }
}
</style>